import React from "react"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import InnerWrapper from "../../components/layout/innerWrapper"
import ConnectSidebar from "../../components/layout/connect-sidebar"
import { Link } from "gatsby"
import Iframe from "react-iframe"
import useGCLID from "../../hooks/useGCLID"

import { Row, Col, Breadcrumb } from "react-bootstrap"

const ContactUsPage = () => {
  return (
    <Layout className="main inner-page-with-banner">
      <SEO
        title={`Walpole Outdoors - Contact Us`}
        description={`The standard all other fence and outdoor structures are measured by.`}
      />

      <InnerWrapper>
        <div className="page-hero"></div>

        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/connect/contact-us">Contact Us</Link>
          </Breadcrumb.Item>
        </Breadcrumb>

        <Row className="generalcontent">
          <Col md={3}>
            <ConnectSidebar />
          </Col>
          <Col md={9}>
            <h1 className="generalheader">Contact Us</h1>
            <p>
              Questions? Contact us today and discover how Walpole Outdoors can
              create your ideal outdoor spaces and structures, from consultation
              to concept to completion.
            </p>
            <Iframe
              className="scheduleFrame"
              url={useGCLID("https://www.tfaforms.com/4861498", "tfa_59")}
              frameBorder="0"
            />
          </Col>
        </Row>
      </InnerWrapper>
    </Layout>
  )
}

export default ContactUsPage
